export default class Goal {
  id!: string;
  name!: string;
  flavor!: string;
  objective!: string;
  metric!: string;
  recommendations?: string;
  helpUrl?: string;
  options?: string[];
  selectedOption?: string;
  conflicts?: string[];

  constructor(goal: Goal) {
    this.id = goal.id;
    this.name = goal.name;
    this.flavor = goal.flavor;
    this.objective = goal.objective;
    this.metric = goal.metric;
    this.recommendations = goal.recommendations;
    this.helpUrl = goal.helpUrl;
    this.options = goal.options;
    this.selectedOption = goal.selectedOption;
    this.conflicts = goal.conflicts;
  }
}
