<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />
  <div class="goal-box">
    <div class="goal-name">{{ goal.name }}</div>
    <div class="goal-flavor">{{ goal.flavor }}</div>
    <div class="goal-objective" v-if="goal.objective">
      Cél: {{ goal.objective }}
    </div>
    <div class="goal-options" v-if="goal.options">
      Válassz:
      <select v-model="goal.selectedOption">
        <option v-for="opt in goal.options" :key="opt">{{ opt }}</option>
      </select>
    </div>
    <div class="goal-metric" v-if="goal.metric">
      {{ goal.metric }}
    </div>
    <div class="goal-recommended" v-if="goal.recommended">
      Tippek: {{ goal.recommended }}
    </div>
    <div class="goal-help-url" v-if="goal.helpUrl">
      További (spoiler) infó
      <a v-bind:href="goal.helpUrl" target="_blank"> itt található. </a>
    </div>
    <div v-if="canReroll">
      <button class="reroll-button" @click="rerollGoal">
        <i class="fa fa-refresh"></i>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import Goal from "@/model/Goal";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    goal: Goal,
    canReroll: Boolean,
  },
})
export default class GoalBox extends Vue {
  goal!: Goal;

  rerollGoal(): void {
    console.log("RerollGoal called in GoalBox");
    this.$emit("rerollGoal", this.goal.id);
  }
}
</script>

<style scoped lang="scss">
.goal-help-url {
  margin-top: 20px;
}
.goal-name {
  font-weight: bold;
  font-size: larger;
  padding-bottom: 20px;
}
.goal-options {
  margin: 20px;
}
.goal-box {
  margin: 20px auto 20px auto;
  max-width: 600px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 30px;
  display: inline-grid;
  margin: 20px;
}
.reroll-button {
  background-color: royalblue;
  border: none;
  color: white;
  padding: 16px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
  margin-top: 10px;
}
.reroll-button:hover {
  background-color: navy;
}
</style>
