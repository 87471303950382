import goalJson from "../assets/goals.json";
import Goal from "@/model/Goal";

export default class GoalService {
  private static goals: Goal[] = [];

  private static getGoals(): Goal[] {
    if (this.goals.length < 1) {
      this.goals = [];
      goalJson.forEach((item) => {
        const goal = new Goal(item as Goal);
        this.goals.push(goal);
      });
    }

    return this.goals;
  }

  public static getRandomGoal(currentGoals: Goal[]): Goal {
    const goals = this.getGoals() as Goal[];

    const availableGoals = goals.filter((goal) => {
      const isDuplicate = currentGoals.includes(goal);
      const isConflicting = currentGoals.find((current) =>
        current.conflicts?.includes(goal.id)
      );

      return !isDuplicate && !isConflicting;
    });

    return availableGoals[Math.floor(Math.random() * availableGoals.length)];
  }

  public static getGoalById(id: string): Goal {
    const goals = this.getGoals();
    const goal = goals.find((goal) => goal.id == id);
    if (!goal) {
      throw new Error(`No such goal found: ${id}`);
    }
    return goal;
  }
}
