
import Goal from "@/model/Goal";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    goal: Goal,
    canReroll: Boolean,
  },
})
export default class GoalBox extends Vue {
  goal!: Goal;

  rerollGoal(): void {
    console.log("RerollGoal called in GoalBox");
    this.$emit("rerollGoal", this.goal.id);
  }
}
