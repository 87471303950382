<template>
  <h2>Stellaris Secret Goal Randomizer</h2>
  <div class="wrapper">
    <goal-box
      v-for="current in selectedGoals"
      :key="current.id"
      :goal="current"
      :can-reroll="rerollsLeft > 0"
      @reroll-goal="rerollGoal"
    />
  </div>
  <div id="save-box">
    <div>Jelszó a megosztáshoz:</div>
    <input
      id="password"
      placeholder="Írd ide a jelszót..."
      v-model="password"
    />
    <div>Titkosított szelekció:</div>
    <input id="save-text" v-model="saveText" />
    <div class="save-hint">
      (Ezt a szöveget ments le, mert a jelszóval együtt vissza tudod állítani a
      jelenlegi állapotot.)
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import CryptoJS from "crypto-js";

import GoalService from "./service/GoalService";
import GoalBox from "./components/GoalBox.vue";
import Goal from "./model/Goal";

@Options({
  components: {
    GoalBox,
  },
})
export default class App extends Vue {
  private static maxNumberOfGoals = 3;

  private selectedGoals: Goal[] = [];
  private password = "";
  private rerollsLeft = 3;

  created(): void {
    for (let i = 0; i < App.maxNumberOfGoals; i++) {
      this.selectedGoals.push(GoalService.getRandomGoal(this.selectedGoals));
    }
  }

  rerollGoal(goalId: string): void {
    console.log("Reroll goal called in App");

    if (
      !confirm(
        "Biztosan új random célt szeretnél kérni? Még " +
          this.rerollsLeft +
          " új célt kérhetsz!"
      )
    ) {
      return;
    }

    if (this.rerollsLeft < 1) {
      return;
    }

    const goalToReroll = this.selectedGoals.find(
      (current) => current.id == goalId
    );

    if (!goalToReroll) {
      return;
    }

    const indexOfGoalToReroll = this.selectedGoals.indexOf(goalToReroll);

    this.selectedGoals.splice(
      indexOfGoalToReroll,
      1,
      GoalService.getRandomGoal(this.selectedGoals)
    );
    this.rerollsLeft = this.rerollsLeft - 1;
  }

  get saveText(): string {
    const message = this.selectedGoals
      .map((goal) => {
        let option = goal.selectedOption;
        if (option) {
          return goal.id + ":" + option;
        }
        return goal.id;
      })
      .reduce((prev, current) => {
        return (current += "|" + prev);
      });
    return CryptoJS.AES.encrypt(message, this.password).toString();
  }

  set saveText(newValue: string) {
    const message = CryptoJS.AES.decrypt(newValue, this.password).toString(
      CryptoJS.enc.Utf8
    );

    if (message.length < 1) {
      return;
    }

    const parts: string[] = message.split("|");
    const selected: Goal[] = [];
    parts.forEach((part) => {
      const values = part.split(":");
      const goal = GoalService.getGoalById(values[0]);
      if (values[1]) {
        goal.selectedOption = values[1];
      }
      selected.push(goal);
    });
    this.selectedGoals = selected;
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#save-box {
  text-align: center;
}
#save-box > input {
  text-align: center;
}

#save-text {
  width: 70%;
}

.save-hint {
  font-size: smaller;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
</style>
